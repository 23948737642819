import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <p>
          Something is going on here...
        </p>


        <div>
        Welcome on the SuBa-KSZE site. Thanks for stopping by.
      </div>

      
      </header>

      
    </div>
  );
}

export default App;
